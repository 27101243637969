<template>
  <div class="container">
    <van-loading class="loading" color="#e7cc8f" text-color="#e7cc8f" v-show="loading" size="24px">加载中...</van-loading>
    <div class="qinfo" v-show="loading == false && hasQuestionnaire" :style="questionnaire.backgroundImageUrl &&
      questionnaire.backgroundImageUrl != ''
      ? 'background: url(' +
      questionnaire.backgroundImageUrl +
      '); background-size: contain;'
      : ''
      ">
      <!-- <div v-show="loading == false" > -->
      <div v-if="hasQuestionnaire">
        <div class="acontent" v-html="questionnaire.detailsHtml"></div>

        <!-- 问卷问题 -->
        <div v-for="(item, index) in filteredQuestionList" :key="item.id.toString()" class="questionList" :style="item.haserror === true
          ? 'border: 2px solid red;'
          : 'border: 2px solid rgba(255,255,255,0);'
          " v-show="item.isActionTarget != undefined && (!item.isActionTarget || item.isActionShow)"
          :id="item.id.toString()">
          <div class="question">
            <h3>
              <span v-if="item.isMust" class="question-start" style="color: #ff6d56;">*</span>
              {{ index + 1 }}、{{ item.title }}
              {{
                item.typeId == "5" && item.answerText > 0
                ? "(" + item.answerText + "分)"
                : ""
              }}
            </h3>
            <h4>
              {{ item.summary }}

            </h4>
            <van-image v-if="item.imageUrl != undefined && item.imageUrl.length > 10" :src="item.imageUrl">
              <template v-slot:error>图片加载失败</template>
            </van-image>
          </div>
          <div v-if="item.typeId == 1" class="option">
            <van-radio-group v-model="item.checked" @change="checkquestion(index)">
              <van-radio :name="option.id.toString()" v-for="(option, oindex) in item.optionList"
                :key="option.id.toString()">{{ GetOptionTitle(option.title, oindex) }}
                <van-image v-if="option.imageUrl != undefined && option.imageUrl.length > 10
                  " :src="option.imageUrl">
                  <template v-slot:error>图片加载失败</template>
                </van-image>
                <div v-html="option.detailsHtml"></div>
              </van-radio>
            </van-radio-group>

            <div v-if="HasText || item.hasOther" class="questionInput">
              其他
              <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
                :error-message="item.errormessage" @blur="checkInput(index)" />
            </div>

          </div>
          <div v-if="item.typeId == 2" class="option">
            <van-checkbox-group v-model="item.checked" @change="checkquestion(index)">
              <van-checkbox shape="square" :name="option.id.toString()" v-for="(option, oindex) in item.optionList"
                :key="option.id.toString()">{{ GetOptionTitle(option.title, oindex) }}
                <van-image v-if="option.imageUrl != undefined && option.imageUrl.length > 10
                  " :src="option.imageUrl">
                  <template v-slot:error>图片加载失败</template>
                </van-image>
                <div v-html="option.detailsHtml"></div>
              </van-checkbox>
            </van-checkbox-group>
            <div v-if="HasText || item.hasOther" class="questionInput">
              其他
              <van-field size="large" v-model="item.answerText" :disabled="submitDisabled" style="
            background-color:#fff" :error-message="item.errormessage" @blur="checkInput(index)" />
            </div>



          </div>
          <!-- 文本 -->
          <div v-if="item.typeId == 3" class="questionInput">
            <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @blur="checkInput(index)" />
          </div>
          <!-- 整数 -->
          <div v-if="item.typeId == 32" class="questionInput">
            <van-field size="large" type="digit" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @blur="checkInput(index)" />
          </div>
          <!-- 数字 -->
          <div v-if="item.typeId == 33" class="questionInput">
            <van-field size="large" type="number" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @blur="checkInput(index)" />
          </div>
          <!-- 手机号 -->
          <div v-if="item.typeId == 31" class="questionInput">
            <van-field size="large" type="tel" maxlength="11" v-model="item.answerText" :disabled="submitDisabled"
              :error-message="item.errormessage" @blur="checkInputPhone(index)" />
          </div>
          <!-- 备注 -->
          <div v-if="item.typeId == 34" class="questionInput">
            <van-field v-model="item.answerText" :disabled="submitDisabled" :error-message="item.errormessage"
              @blur="checkInput(index)" rows="2" autosize type="textarea" />
            <!-- <textarea
              class="texta"
              rows="2"
              autosize
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
              @input="checkInput(index)"
            ></textarea> -->
          </div>
          <!-- 日期 -->
          <div v-if="item.typeId == 4" class="questionInput">
            <van-cell color="#646566" title="日期" :value="item.answerText" @click="item.showcalendar = true" />
            <van-calendar v-model="item.showcalendar" @confirm="onConfirm($event, index)" />
          </div>
          <!-- 评分 -->
          <div v-if="item.typeId == 5" class="option">
            <van-rate v-model="item.answerText" @change="onChange($event, index)" count="10" color="#ffd21e"
              void-icon="star" void-color="#eee" size="25px" />
          </div>
          <div v-if="item.typeId == 7" class="option">
            <ModalNps :score="item.answerText" @change="onChangeNPS($event, index)" optiondesc1="不可能" optiondesc2="极有可能">
            </ModalNps>
          </div>
          <!-- 展现设计图 -->

          <div v-show="item.id == 'c8651560-6621-48c1-a307-b3beefd7afc7' && getProductImageUrl1.length > 0"
            class="popdiv">
            <van-popup v-model="showpop1" round :style="{ width: '100%', maxWidth: '750px', height: '75%' }"
              duration="0.6" closeable close-icon="./image/close.png">
              <h2
                style="text-align: center;padding: 30px 0 30px 0;margin:0;background: linear-gradient(to bottom, rgb(253 248 217) 0%, #fff 50%, #fff 100%);">
                请审阅您的创意设计效果图</h2>
              <van-image :src="getProductImageUrl1">
                <template v-slot:error>图片加载失败</template>
              </van-image>
            </van-popup>
          </div>

          <!-- <div v-show="item.id == 'c8651560-6621-48c1-a307-b3beefd7afc7' && getProductImageUrl1.length > 0"
            style="border: 2px solid rgb(224 224 224); background: #f2f2f5;">
              <h2
                style="text-align: center;padding: 30px 0 30px 0;margin: 1px 0 0px 0;background: linear-gradient(to bottom, #625649 0%, #dbbf7e 50%, #f2f2f5 100%);">
                请审阅您的创意设计效果图</h2>
              <van-image :src="getProductImageUrl1">
                <template v-slot:error>图片加载失败</template>
              </van-image>
          </div> -->

          <!-- 展现设计图 -->

          <div v-show="item.id == '39dde529-e663-4b47-97c5-ac09420fa0f7' && getProductImageUrl2.length > 0"
            class="popdiv">
            <van-popup v-model="showpop2" round :style="{ width: '100%', height: '75%' }" duration="1" closeable
              close-icon="./image/close.png">
              <h2
                style="text-align: center;padding: 50px 0 50px 0;margin: 0;background: linear-gradient(to bottom, rgb(253 248 217) 0%, #fff 50%, #fff 100%);">
                请审阅您的创意设计效果图</h2>
              <van-image :src="getProductImageUrl2" style=" margin: 2px 15px 10px 15px;">
                <template v-slot:error>图片加载失败</template>
              </van-image>
            </van-popup>
          </div>
          <!-- <div v-show="item.id == '39dde529-e663-4b47-97c5-ac09420fa0f7' && getProductImageUrl2.length > 0" style="
    border: 2px solid rgb(224 224 224); background: #f2f2f5;">
              <h2
                style="text-align: center;padding: 50px 0 50px 0;margin: 1px 0 0px 0;background: linear-gradient(to bottom, #625649 0%, #dbbf7e 50%, #f2f2f5 100%);">
                请审阅您的创意设计效果图</h2>
              <van-image :src="getProductImageUrl2" style=" margin: 2px 15px 10px 15px;">
                <template v-slot:error>图片加载失败</template>
              </van-image>
          </div> -->




        </div>


        <div v-show="filteredQuestionList[0].checked">
          <div v-if="questionnaire.summary != undefined" style="margin: 0 10px 20px 20px">
            <h3>{{ questionnaire.summary }}</h3>
          </div>
          <div class="questionInput" v-if="HasUserName" :style="usernameerror != ''
            ? 'border: 2px solid red;background-color:#fff'
            : 'border: 2px solid rgba(255,255,255,0);background-color:#fff'
            ">
            <van-field v-model="answer.userName" size="large" label="姓名" placeholder="请输入姓名" maxlength="15"
              :disabled="submitDisabled" :error-message="usernameerror" @input="inputUserName" />
          </div>
          <div class="questionInput" v-if="HasPhone" :style="phoneerror != ''
            ? 'border: 2px solid red; background-color:#fff'
            : 'border: 2px solid rgba(255,255,255,0); background-color:#fff'
            ">
            <van-field size="large" type="tel" v-model="answer.phone" :disabled="submitDisabled" label="手机号"
              maxlength="11" placeholder="请输入手机号" :error-message="phoneerror" @input="inputPhone" />
          </div>

          <div v-if="HasAddress" class="questionInput" :style="addresserror != ''
            ? 'border: 2px solid red;background-color:#fff'
            : 'border: 2px solid rgba(255,255,255,0); background-color:#fff'
            ">
            <van-field size="large" v-model="answer.address" :disabled="submitDisabled" label="地址" placeholder="请输入地址"
              :error-message="addresserror" @input="inputAddress" />
          </div>

          <div class="submit">
            <van-button v-if="questionnaire.showType == 1 || questionnaire.showType == 500" round block
              :disabled="submitDisabled"
              style="background-color: #e5ca8b; color: rgb(161 1 1); font-size: larger; font-weight: bold;"
              native-type="submit" @click="onsubmit()">
              提交
            </van-button>
            <div class="submitSuccess" id="posterImage" v-show="questionnaire.showType == 1">
              {{ showMessage }}
            </div>

            <div class="submitError" v-show="questionnaire.showType > 1">
              {{ showMessage }}
            </div>
          </div>
        </div>
        <div v-if="poster != undefined && poster.length > 0">
          <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
            长按图片，转发给朋友
          </van-notice-bar>
          <!-- 轮播图 -->
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item v-for="(image, index) in poster" :key="index">
              <img v-lazy="image.qrPrviewImage" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>

    <div v-if="loading == false && !hasQuestionnaire" style="padding-top: 15px">
      <!-- <div style="color: white; text-align: center">
        {{ showTypeInfo }}
      </div> -->
      <div class="submitError">
        {{ showMessage }}
      </div>
      <div v-if="poster != undefined && poster.length > 0">
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
          长按图片，转发给朋友
        </van-notice-bar>
        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item v-for="(image, index) in poster" :key="index">
            <img v-lazy="image.qrPrviewImage" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div id="ad-container">
      <iframe :src="adUrlWithParams" frameborder="0" id="adiframe"></iframe>
    </div>
  </div>
</template>

<script>
import { Toast, } from "vant";
import ModalNps from '../../components/ModalNps.vue';

import {
  getDevice,
  getQuestionnaire,
  AddAnswer,
  getPoster2,
  AdPost,
  GetActionList,
} from "@/api/question";

export default {
  props: {
    HasAddress: {
      type: Boolean,
      default: false,
    },
    HasText: {
      type: Boolean,
      default: false,
    },
    HasPhone: {
      type: Boolean,
      default: true,
    },
    HasUserName: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      questionnaire: {},
      hasQuestionnaire: true,
      phoneerror: "",
      addresserror: "",
      usernameerror: "",
      submitDisabled: false,
      showMessage: "",
      showTypeInfo: "",
      answer: {
        questionnaireId: "",
        parentDeviceId: "00000000-0000-0000-0000-000000000000",
        sourceType: 1,
        hasPhone: null,
        deviceId: "",
        userCode: "",
        userName: "",
        phone: "",
        position: "",
        province: "",
        city: "",
        county: "",
        address: "",
        questionList: [],
      },
      producturl: "",
      poster: [],
      actionList: [],
      showpop1: false,
      showpop2: false,
      isWeixin: navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1,

    };
  },

  created() {
    this.initData();
  },

  computed: {


    adUrlWithParams() {
      // 根据参数构建广告URL
      const adUrl = '/ad3.html'; // 你的广告HTML文件的路径
      const queryString = window.location.search;
      return `${adUrl}${queryString}`;
    },




    filteredQuestionList() {

      var list = [];
      if (this.questionnaire.questionList != undefined) {
        this.questionnaire.questionList.map((item) => {
          if (!item.isActionTarget || item.isActionShow) {
            list.push(item);
            return item;
          }
        });
      }
      return list;
    },
    getProductImageUrl() {
      //this.producturl="";
      // 五粮液
      let url = "";
      if (this.questionnaire.questionList[0].checked == "47dc5074-ee3d-4e2c-8e59-adad4cb33628") {
        url = "A";
        if (this.questionnaire.questionList[1].checked == "c83d508c-c7a8-4fb3-9d89-482b59ba858e")//方瓶
        {
          url += "_A";
        }
        else if (this.questionnaire.questionList[1].checked == "c63cc95e-89fe-424b-b153-5886e00b086a")//萝卜瓶
        {
          url += "_B"
        }
        else {
          return "";
        }

        if (this.questionnaire.questionList[3].checked == "0e9cef4d-0ddd-4a29-83b7-5689dc638d7a")//云龙
        {
          url += "_A";
        }
        else if (this.questionnaire.questionList[3].checked == "771569e8-e9d2-4117-aec7-ab89df29771a")//五龙
        {
          url += "_B"
        }
        else {
          return "";
        }

        if (this.questionnaire.questionList[5].checked == "484e2c69-cbdf-4337-982d-3082d8ece7f3")//金色
        {
          url += "_A";
        }
        else if (this.questionnaire.questionList[5].checked == "42df44ed-5796-4538-ba5e-790cf0440abd")//五色
        {
          url += "_B"
        }
        else {
          return "";
        }

        if (this.questionnaire.questionList[7].checked == "f5c3cba3-34c3-4a83-86dd-7fe15daa70a5") {
          url += "_A";
        }
        else if (this.questionnaire.questionList[7].checked == "480ad865-ad06-4377-93b6-671ca2d58684") {
          url += "_B"
        }
        else {
          return "";
        }


      }
      else if (this.questionnaire.questionList[0].checked == "c73c6337-4e98-4414-80bb-4fbd09827b95") {
        url = "B";
        if (this.questionnaire.questionList[2].checked == "bef6ae2a-2047-4bc3-b83d-f644f0db5e53") {
          url += "_A";
        }
        else if (this.questionnaire.questionList[2].checked == "46fc86bf-0d8b-4bb6-88f3-a2499f5adeed") {
          url += "_B"
        }
        else {
          return "";
        }

        if (this.questionnaire.questionList[4].checked == "d94851bd-a2a4-404f-8895-1bc118c5e8e2") {
          url += "_A";
        }
        else if (this.questionnaire.questionList[4].checked == "72a6acae-873d-4588-a2b6-34b31da3312e") {
          url += "_B"
        }
        else {
          return "";
        }

        if (this.questionnaire.questionList[6].checked == "6d1ac698-f5a3-46bd-a0e2-94a1586e4273") {
          url += "_A";
        }
        else if (this.questionnaire.questionList[6].checked == "a7e6b29c-fb30-4112-bb49-bd4aa4b3d706") {
          url += "_B"
        }
        else {
          return "";
        }

        if (this.questionnaire.questionList[8].checked == "0ee860ea-18a4-4f95-999c-9e7fcb831e03") {
          url += "_A";
        }
        else if (this.questionnaire.questionList[8].checked == "6b92c229-75d5-4cec-af75-2e079f178901") {
          url += "_B"
        }
        else {
          return "";
        }
      }
      return "./image/long/" + url + ".webp";
    },
    //五粮液图片
    getProductImageUrl1() {
      var url = "A";
      if (this.questionnaire.questionList[1].checked == "c83d508c-c7a8-4fb3-9d89-482b59ba858e")//方瓶
      {
        url += "_A";
      }
      else if (this.questionnaire.questionList[1].checked == "c63cc95e-89fe-424b-b153-5886e00b086a")//萝卜瓶
      {
        url += "_B"
      }
      else {
        return "";
      }

      if (this.questionnaire.questionList[2].checked == "0e9cef4d-0ddd-4a29-83b7-5689dc638d7a")//云龙
      {
        url += "_A";
      }
      else if (this.questionnaire.questionList[2].checked == "771569e8-e9d2-4117-aec7-ab89df29771a")//五龙
      {
        url += "_B"
      }
      else {
        return "";
      }

      if (this.questionnaire.questionList[3].checked == "484e2c69-cbdf-4337-982d-3082d8ece7f3")//金色
      {
        url += "_A";
      }
      else if (this.questionnaire.questionList[3].checked == "42df44ed-5796-4538-ba5e-790cf0440abd")//五色
      {
        url += "_B"
      }
      else {
        return "";
      }

      if (this.questionnaire.questionList[4].checked == "f5c3cba3-34c3-4a83-86dd-7fe15daa70a5") {
        url += "_A";
      }
      else if (this.questionnaire.questionList[4].checked == "480ad865-ad06-4377-93b6-671ca2d58684") {
        url += "_B"
      }
      else {
        return "";
      }
      return "./image/long/" + url + ".webp";
    },
    //天安门图片
    getProductImageUrl2() {
      var url = "B";
      if (this.questionnaire.questionList[5].checked == "bef6ae2a-2047-4bc3-b83d-f644f0db5e53") {
        url += "_A";
      }
      else if (this.questionnaire.questionList[5].checked == "46fc86bf-0d8b-4bb6-88f3-a2499f5adeed") {
        url += "_B"
      }
      else {
        return "";
      }

      if (this.questionnaire.questionList[6].checked == "d94851bd-a2a4-404f-8895-1bc118c5e8e2") {
        url += "_A";
      }
      else if (this.questionnaire.questionList[6].checked == "72a6acae-873d-4588-a2b6-34b31da3312e") {
        url += "_B"
      }
      else {
        return "";
      }

      if (this.questionnaire.questionList[7].checked == "6d1ac698-f5a3-46bd-a0e2-94a1586e4273") {
        url += "_A";
      }
      else if (this.questionnaire.questionList[7].checked == "a7e6b29c-fb30-4112-bb49-bd4aa4b3d706") {
        url += "_B"
      }
      else {
        return "";
      }

      if (this.questionnaire.questionList[8].checked == "0ee860ea-18a4-4f95-999c-9e7fcb831e03") {
        url += "_A";
        return "";//九龙壁没有合成图
      }
      else if (this.questionnaire.questionList[8].checked == "6b92c229-75d5-4cec-af75-2e079f178901") {
        url += "_B"
      }
      else {
        return "";
      }
      return "./image/long/" + url + ".webp";
    }
  },

  methods: {
    async initData() {
      let questionnaireId = this.$route.query.q;
      let userCode = this.$route.query.u;
      let deviceId = localStorage.getItem("deviceId" + questionnaireId);
      if (deviceId == undefined || deviceId == "" || deviceId == null) {
        var dresult = await getDevice(questionnaireId, userCode);
        localStorage.setItem("deviceId" + questionnaireId, dresult.data.data);
        deviceId = dresult.data.data;
      }

      this.answer.deviceId = deviceId;
      this.answer.questionnaireId = questionnaireId;
      this.answer.userCode = userCode;
      this.answer.hasPhone = this.HasPhone;
      let parentDeviceId = this.$route.query.d;
      if (
        parentDeviceId == undefined ||
        parentDeviceId == "" ||
        parentDeviceId == null
      ) {
        this.answer.parentDeviceId = "00000000-0000-0000-0000-000000000000";
      } else {
        this.answer.parentDeviceId = parentDeviceId;
      }

      var quResult = await getQuestionnaire(questionnaireId, deviceId);

      if (quResult.data.success) {
        var myquestionnaire = quResult.data.data;

        if (myquestionnaire.userTitle && myquestionnaire.userTitle.length) {
          document.title = myquestionnaire.userTitle;
        }


        for (
          let index = 0;
          index < myquestionnaire.questionList.length;
          index++
        ) {
          //初始化附加属性值
          myquestionnaire.questionList[index].haserror = false;
          myquestionnaire.questionList[index].errormessage = "";
          if (myquestionnaire.questionList[index].typeId == 5 || myquestionnaire.questionList[index].typeId == 7) {
            myquestionnaire.questionList[index].answerText = 0;
          } else {
            myquestionnaire.questionList[index].answerText = "";
          }

          myquestionnaire.questionList[index].showcalendar = false;
          myquestionnaire.questionList[index].isActionSource = false;
          myquestionnaire.questionList[index].isActionTarget = false;
          myquestionnaire.questionList[index].isActionShow = false;
        }
        this.questionnaire = myquestionnaire;
      } else {
        this.hasQuestionnaire = false;
        this.showMessage = quResult.data.errorMessage;
        Toast(quResult.data.errorMessage);
      }

      //获取逻辑信息
      if (this.questionnaire.showType == 1) {
        var action = await GetActionList(questionnaireId)
        if (action.data.success && action.data.data.length > 0) {
          this.actionList = action.data.data;

          for (let index = 0; index < this.questionnaire.questionList.length; index++) {
            for (let a = 0; a < this.actionList.length; a++) {
              if (this.questionnaire.questionList[index].id == this.actionList[a].questionId) {
                this.questionnaire.questionList[index].isActionSource = true;
              }
              if (this.questionnaire.questionList[index].id == this.actionList[a].actionQuestionId) {
                this.questionnaire.questionList[index].isActionTarget = true;
              }
            }
          }
        }

      }

      if (this.questionnaire.showType > 1) {
        this.submitDisabled = true;
        this.hasQuestionnaire = false;
      }
      if (this.questionnaire.showType == 2) {
        this.showMessage = this.questionnaire.title + "调研未开始，敬请期待！";
      }
      if (this.questionnaire.showType == 3) {
        this.showMessage =
          this.questionnaire.title + "调研已结束，期待您下次参与!";
      }
      if (this.questionnaire.showType == 4) {
        this.showMessage = "您已完成本次问卷，感谢您的帮助与支持!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (this.questionnaire.showType == 5) {
        this.showMessage = "您已完成本次问卷，感谢您的帮助与支持!!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (this.questionnaire.showType == 6) {
        this.showMessage = "您已完成本次问卷，感谢您的帮助与支持!!!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (
        this.questionnaire.showType >= 4 &&
        this.questionnaire.showType <= 6
      ) {
        await this.getMyPoster();
      }

      this.loading = false;
    },
    onChange(value, index) {
      Toast("评分：" + value + "分");
      this.filteredQuestionList[index].haserror = false;
      this.filteredQuestionList[index].answerText = value;
      this.handlerScoreChange(value, index);
    },
    onChangeNPS(value, index) {
      Toast(value);
      this.filteredQuestionList[index].haserror = false;
      this.filteredQuestionList[index].answerText = value;
      this.handlerScoreChange(value, index);
    },
    handlerScoreChange(value, index) {
      if (this.filteredQuestionList[index].isActionSource) {
        //
        for (let a = 0; a < this.actionList.length; a++) {
          //找到 触发逻辑的问题 this.actionList[a]
          if (this.filteredQuestionList[index].id == this.actionList[a].questionId) {
            for (let q = 0; q < this.questionnaire.questionList.length; q++) {
              //找到 逻辑问题  this.questionnaire.questionList[q]
              if (this.questionnaire.questionList[q].id == this.actionList[a].actionQuestionId) {
                if (this.actionList[a].selectNumMin <= value && value <= this.actionList[a].selectNumMax) {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                }
                else {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                }
                break;
              }
            }
          }


        }
      }
    },
    checkquestion(index) {

      this.filteredQuestionList[index].haserror = false;
      if (this.filteredQuestionList[index].typeId == 2 && this.filteredQuestionList[index].checked) {
        if (this.filteredQuestionList[index].maxLength > 0 && this.filteredQuestionList[index].checked.length > this.filteredQuestionList[index].maxLength) {
          this.filteredQuestionList[index].haserror = true;
          Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最多选" + this.filteredQuestionList[index].maxLength + "项");
        }
        if (this.filteredQuestionList[index].minLength > 0 && this.filteredQuestionList[index].checked.length < this.filteredQuestionList[index].minLength) {
          this.filteredQuestionList[index].haserror = true;
          Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最少选" + this.filteredQuestionList[index].minLength + "项");
        }
      }
      if (this.filteredQuestionList[index].isActionSource) {
        //
        //for (let a = this.actionList.length-1; a >=0; a--) 
        for (let a = 0; a < this.actionList.length; a++) {
          //找到 触发逻辑的问题 this.actionList[a]
          if (this.filteredQuestionList[index].id == this.actionList[a].questionId) {

            for (let q = 0; q < this.questionnaire.questionList.length; q++) {
              //找到 逻辑问题  this.questionnaire.questionList[q]
              if (this.questionnaire.questionList[q].id == this.actionList[a].actionQuestionId) {

                //选中任意一项
                if (this.actionList[a].selectOption == "00000000-0000-0000-0000-000000000000") {
                  if (this.filteredQuestionList[index].checked.length >= 0) //选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                  break;
                }

                if (this.actionList[a].selectType == 1)// 选中逻辑
                {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  if (this.filteredQuestionList[index].checked.indexOf(this.actionList[a].selectOption) >= 0) //选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                }
                if (this.actionList[a].selectType == 2)// 未选中
                {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  if (this.filteredQuestionList[index].checked.indexOf(this.actionList[a].selectOption) == -1) //未选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                }
                break;
              }
            }

          }
        }
      }



      if (index < 5) {
        var url = this.getProductImageUrl1;
        if (url.length > 0) {
          this.showpop1 = true
        }

        var url2 = this.getProductImageUrl2;
        if (url2.length > 0) {
          this.showpop2 = true
        }
      }


    },
    checkInput(index) {
      if (
        (this.filteredQuestionList[index].typeId == 3 ||
          (this.filteredQuestionList[index].typeId >= 31 &&
            this.filteredQuestionList[index].typeId <= 39)) &&
        this.filteredQuestionList[index].isMust
      ) {
        if (
          (this.filteredQuestionList[index].minLength ||
            this.filteredQuestionList[index].minLength > 0) &&
          this.filteredQuestionList[index].answerText.replace(
            /(^\s*)|(\s*$)/g,
            ""
          ).length < this.filteredQuestionList[index].minLength
        ) {
          this.filteredQuestionList[index].errormessage =
            "最小长度是" +
            this.filteredQuestionList[index].minLength +
            "个字符";
          this.filteredQuestionList[index].haserror = true;
        }
        else if (this.filteredQuestionList[index].answerText == "" && this.filteredQuestionList[index].isMust) {
          this.filteredQuestionList[index].haserror = true;
          this.filteredQuestionList[index].errormessage = "不可为空";
        }
        else {
          this.filteredQuestionList[index].haserror = false;
          this.filteredQuestionList[index].errormessage = "";
        }
      }
    },
    checkInputPhone(index) {
      if (this.filteredQuestionList[index].answerText.length < 11) {
        this.filteredQuestionList[index].errormessage = "";
        return;
      }
      var phonereg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.filteredQuestionList[index].answerText)) {
        this.filteredQuestionList[index].errormessage =
          "请填写有效的手机号码";
        this.filteredQuestionList[index].haserror = true;
      } else {
        this.filteredQuestionList[index].errormessage = "";
        this.filteredQuestionList[index].haserror = false;
      }
    },
    async onsubmit() {
      this.submitDisabled = true;
      // var checked = true;
      // var checkeditem;
      var itemerror = "";
      for (
        let index = 0;
        index < this.filteredQuestionList.length;
        index++
      ) {
        // element是所有信息
        const element = this.filteredQuestionList[index];
        if (element.typeId == 1
          && element.isMust
          && element.checked == undefined
          && element.answerText == ""
        ) {
          element.haserror = true;
        } else if (
          element.typeId == 2 && element.isMust
          && (element.checked == undefined || element.checked.length == 0)
          && element.answerText == ""
        ) {
          element.haserror = true;
        } else if (
          (element.typeId == 4 ||
            element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust &&
          element.answerText == ""
        ) {
          element.haserror = true;
          this.filteredQuestionList[index].errormessage == "不可为空"
        }
        else if ((element.typeId == 5 || element.typeId == 7) && element.answerText == 0) {
          element.haserror = true;
        }

        if (element.typeId == 31 && element.isMust) {
          if (!phonereg.test(element.answerText)) {
            element.errormessage = "请填写有效的手机号码";
            element.haserror = true;
          } else {
            element.haserror = false;
            element.errormessage = "";
          }
        }

        // str.replace(/(^\s*)|(\s*$)/g, "")
        if (
          (element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust
        ) {
          if (
            (element.minLength || element.minLength > 0) &&
            element.answerText.replace(/(^\s*)|(\s*$)/g, "").length <
            element.minLength
          ) {
            element.errormessage = "最小长度是" + element.minLength + "个字符";
            element.haserror = true;
          }
        }


      }

      for (
        let index = 0;
        index < this.filteredQuestionList.length;
        index++
      ) {
        // element是所有信息
        const element = this.filteredQuestionList[index];


        if (this.filteredQuestionList[index].typeId == 2 && this.filteredQuestionList[index].checked) {
          if (this.filteredQuestionList[index].maxLength > 0 && this.filteredQuestionList[index].checked.length > this.filteredQuestionList[index].maxLength) {
            this.filteredQuestionList[index].haserror = true;
            Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最多选" + this.filteredQuestionList[index].maxLength + "项");
            document.getElementById(element.id).scrollIntoView();
            this.submitDisabled = false;
            return;
          }
          if (this.filteredQuestionList[index].minLength > 0 && this.filteredQuestionList[index].checked.length < this.filteredQuestionList[index].minLength) {
            this.filteredQuestionList[index].haserror = true;
            Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最少选" + this.filteredQuestionList[index].minLength + "项");
            document.getElementById(element.id).scrollIntoView();
            this.submitDisabled = false;
            return;
          }
        }



        //第一个错误,页面会滚动到
        if (element.haserror) {
          itemerror =
            index +
            1 +
            "、" +
            element.title +
            (element.typeId <= 2 ? " 请选择" : " 请填写");
          //checked = false;
          Toast(itemerror);
          document.getElementById(element.id).scrollIntoView();
          this.submitDisabled = false;
          return;
        }
      }

      if (this.HasPhone) {
        var phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!phonereg.test(this.answer.phone)) {
          this.phoneerror = "请填写有效的手机号码";
        } else {
          this.phoneerror = "";
        }
      }

      if (this.HasUserName) {
        if (this.answer.userName == "") {
          this.usernameerror = "请填写姓名";
        } else {
          this.usernameerror = "";
        }
      }
      if (this.HasAddress) {
        if (this.answer.address == "") {
          this.addresserror = "请填写地址";
        } else {
          this.addresserror = "";
        }
      }

      // if (!checked && checkeditem !== undefined) {
      //   Toast(itemerror);
      //   document.getElementById(checkeditem.id).scrollIntoView();
      //   this.submitDisabled = false;
      //   return;
      // }
      if (
        this.phoneerror != "" ||
        this.usernameerror != "" ||
        this.addresserror != ""
      ) {
        this.submitDisabled = false;
        return;
      }
      //问卷答案
      this.answer.questionList = [];
      for (
        let index = 0;
        index < this.filteredQuestionList.length;
        index++
      ) {
        const question = this.filteredQuestionList[index];
        var option = {};
        if (question.typeId == 1) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: this.HasText || (question.minLength &&
              question.minLength > 0) ? question.answerText.toString() : "",
          };
        } else if (question.typeId == 2) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: this.HasText || (question.minLength &&
              question.minLength > 0) ? question.answerText.toString() : "",
          };
        } else if (question.typeId >= 3 && question.typeId <= 50) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: question.answerText.toString(),
          };
        }
        this.answer.questionList.push(option);
      }
      // var amapInfo = localStorage.getItem("AMapInfo");
      // if (amapInfo == "SUCCESS") {
      //   this.answer.province = localStorage.getItem("AMapInfo_province");
      //   this.answer.city = localStorage.getItem("AMapInfo_city");
      //   this.answer.county = localStorage.getItem("AMapInfo_district");
      //   this.answer.address = localStorage.getItem("AMapInfo_address");
      //   this.answer.position =
      //     localStorage.getItem("AMapInfo_lng") +
      //     "," +
      //     localStorage.getItem("AMapInfo_lat");
      //   if (this.answer.city == "") {
      //     this.answer.city = this.answer.province;
      //   }
      // }

      try {
        var areulst = await AddAnswer(this.answer);
        if (areulst.data.success) {
          Toast("问卷提交成功");
          this.showMessage = "提交成功，您已完成本次问卷，感谢您的帮助与支持";

          //数据回传
          await this.AdPostData();

          await this.getMyPoster();

        } else {
          Toast(areulst.data.errorMessage);
          this.submitDisabled = false; //questionnaire.showType
          this.questionnaire.showType = 500;
          this.showMessage = "问卷提交失败";
          if (areulst.data.errorCode == "PhoneError") {
            this.showMessage = areulst.data.errorMessage;
          }
        }
      } catch (error) {
        this.submitDisabled = false;
      }
    },
    async AdPostData() {
      const urlParams = new URLSearchParams(window.location.search);
      //参考 https://developers.e.qq.com/docs/guide/user_actions/home_appliance
      var click_ext = urlParams.get('qz_gdt');
      if(!click_ext){
        click_ext = urlParams.get('gdt_vid');
      }
      if (click_ext) {
       
        const myObject = {
          "actions": [
            {
              "outer_action_id": this.deviceId, // 客户唯一行为id
              "action_time": Math.round(new Date().getTime()/1000).toString(),
              "action_type": "RESERVATION", // 必填 行为类型
              "url": "dy.znpz.com.cn", // Web必填 网页域名。其他选填
              "trace": {
                "click_id": click_ext // 必填 click_id
              },
              "action_param": {

              }
            }
          ]
        };
        const jsonString = JSON.stringify(myObject);
        var data = {
          url: "https://tracking.e.qq.com/conv",
          data: jsonString
        }
        await AdPost(data);
      }

    },

    async getMyPoster() {

      try {
        var presult = await getPoster2(
          this.answer.questionnaireId,
          this.answer.userCode,
          this.answer.deviceId,
          2
        );
        if (presult.data.success) {
          this.poster = presult.data.data;
          if (this.hasQuestionnaire && this.poster.length > 0) {
            setTimeout(() => {
              document.getElementById("posterImage").scrollIntoView();
            }, 500);
          }
        }
      } catch (error) {
        console.log(error)
      }

    },
    inputPhone() {
      if (this.answer.phone.length < 11) {
        this.phoneerror = "";
        return;
      }
      var phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.answer.phone)) {
        this.phoneerror = "请填写有效的手机号码";
      } else {
        this.phoneerror = "";
      }
    },
    inputUserName() {
      if (this.answer.userName.length < 1) {
        return;
      }
      if (this.answer.userName == "") {
        this.usernameerror = "请填写姓名";
      } else {
        this.usernameerror = "";
      }
    },
    inputAddress() {
      if (this.answer.address != undefined && this.answer.address.length < 1) {
        return;
      }
      if (this.answer.address == "") {
        this.addresserror = "请填写地址";
      } else {
        this.addresserror = "";
      }
    },

    GetOptionTitle(title, index) {
      var optionIndex = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return optionIndex[index] + "、" + title;
    },

    // getProductImageUrl(){
    //   this.producturl="";
    //   // 五粮液
    //   let url="";
    //    if(this.questionnaire.questionList[0].checked=="47dc5074-ee3d-4e2c-8e59-adad4cb33628"){
    //       url="A";
    //       if(this.questionnaire.questionList[1].checked=="c83d508c-c7a8-4fb3-9d89-482b59ba858e")//方瓶
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[1].checked=="c63cc95e-89fe-424b-b153-5886e00b086a")//萝卜瓶
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }

    //       if(this.questionnaire.questionList[3].checked=="0e9cef4d-0ddd-4a29-83b7-5689dc638d7a")//云龙
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[3].checked=="771569e8-e9d2-4117-aec7-ab89df29771a")//五龙
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }

    //       if(this.questionnaire.questionList[5].checked=="484e2c69-cbdf-4337-982d-3082d8ece7f3")//金色
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[5].checked=="42df44ed-5796-4538-ba5e-790cf0440abd")//五色
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }

    //       if(this.questionnaire.questionList[7].checked=="f5c3cba3-34c3-4a83-86dd-7fe15daa70a5")
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[7].checked=="480ad865-ad06-4377-93b6-671ca2d58684")
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }


    //   }
    //    else if(this.questionnaire.questionList[0].checked=="47dc5074-ee3d-4e2c-8e59-adad4cb33628"){
    //     url="B";
    //     if(this.questionnaire.questionList[2].checked=="bef6ae2a-2047-4bc3-b83d-f644f0db5e53")
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[2].checked=="46fc86bf-0d8b-4bb6-88f3-a2499f5adeed")
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }

    //       if(this.questionnaire.questionList[4].checked=="d94851bd-a2a4-404f-8895-1bc118c5e8e2")
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[4].checked=="72a6acae-873d-4588-a2b6-34b31da3312e")
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }

    //       if(this.questionnaire.questionList[6].checked=="6d1ac698-f5a3-46bd-a0e2-94a1586e4273")
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[6].checked=="a7e6b29c-fb30-4112-bb49-bd4aa4b3d706")
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }

    //       if(this.questionnaire.questionList[8].checked=="0ee860ea-18a4-4f95-999c-9e7fcb831e03")
    //       {
    //         url+="_A";
    //       }
    //       else if(this.questionnaire.questionList[8].checked=="6b92c229-75d5-4cec-af75-2e079f178901")
    //       {
    //         url+="_B"
    //       }
    //       else{
    //         return;
    //       }
    //   }
    //   this.producturl="/image/long/"+url+".png";
    // }
  },
  components: {
    ModalNps
  },
};
</script>

<style lang="less" scoped>
.loading {
  text-align: center;
}

.van-cell {
  background-color: unset;
}

.container {
  margin: 0 auto;
  max-width: 750px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  //padding: 0px 10px 20px 10px;
  /deep/.van-field__control,
  input,
  textarea {
    border: 1px solid #d8d8d8 !important;
  }

  //background: #670001;
  background: linear-gradient(to bottom, #670001 10%, #670001 90%, #670001 100%);
  color: #e7cc8f;
}

.qinfo {
  padding-bottom: 50px;

  /deep/.van-image {
    background: #fff;
  }

  .van-radio {
    background: #fff;
  }

  .van-checkbox {
    background: #fff
  }

  .van-field__body {
    background-color: #fff
  }

  .van-field__control {
    clear: background-color;
    background-color: #fff !important
  }

  /deep/ .van-radio__label {
    color: #000;
    background: #fff;
  }

  /deep/.van-checkbox__label {
    color: #000;
  }

}

.questionList {
  margin: 0px 10px 20px 10px;
}

.questionInput {
  // height: 75px;
  margin: 0px 10px 5px 10px;
  font-size: 16px;
}

.question {
  margin: 20px 10px 20px 10px;
}

.van-checkbox {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}

.van-checkbox[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.van-radio {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}

.van-radio[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.submit {
  padding: 30px 20px 10px;
}

.submitInfo {
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.submitSuccess {
  color: #e0c483;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.submitError {
  color: #fff;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.posterInfo {
  padding-top: 10px;
  font-size: 15px;
  text-align: center;
}

.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }

  /deep/ video {
    max-width: 100% !important;
  }

  /deep/ p {
    text-indent: 35px
  }
}

.my-swipe .van-swipe-item {
  //height: 150px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  //background-color: #39a9ed;
}

.my-swipe img {
  display: inline-block;
  width: 100%;
  height: 10%;
}

.popdiv {
  /deep/ i {
    font-size: 30px;
  }
}
</style>